import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LeadingIssueCard from './leadingIssueCard';
import MoreInsightsCard from './moreInsightsCard';
import GlobalFilters from '../../../Components/globalFilters';
import LocationPath from '../../../Components/locationPath';
import { paths } from '..';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import NewStyledSelect from '../../../SharedStyles/newStyledSelect';
import copyToClipboard from '../../../Util/clipboard';
import Loading from '../../../Components/loading';
import LeadingIssuesQuestions from './leadingIssuesQuestions';
import { useTranslation } from 'react-i18next';

const LeadingIssuesPage = () => {
  const [period, setPeriod] = useState('year');
  const [departmentId, setDepartmentId] = useState('0');
  const [subDepartmentId, setSubDepartmentId] = useState('0');
  const [location, setLocation] = useState('0');
  const visibility_constant = useSelector((store) => store.company?.visibility_constant);

  const [loading, setLoading] = useState(0);
  const { t } = useTranslation();
  const onPeriodChange = (p) => {
    setPeriod(p);
  };

  const onSelectChange = (id) => {
    const departmentId = id === 'all' ? undefined : id;
    setDepartmentId(departmentId);
  };
  const onSubDeptChange = (id) => {
    setSubDepartmentId(id);
  };

  const onLocationChange = (id) => {
    const locationId = id === 'all' ? undefined : id;
    setLocation(locationId);
  };

  const handleChange = (value, setValue) => {
    copyToClipboard(value, setValue);
  };

  return (
    <div>
      {loading ? (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      ) : null}
      <BreadCrumbContainer>
        <LocationPath styles={{ margin: 0 }} multi paths={paths} pathName={t('analytics')} />
        <StyledDiv>
          <NewStyledSelect onChange={handleChange} />
          <GlobalFilters
            period={period}
            onPeriodChange={onPeriodChange}
            department={departmentId}
            onSelectChange={onSelectChange}
            onSubDeptChange={onSubDeptChange}
            onLocationChange={onLocationChange}
          />
        </StyledDiv>
      </BreadCrumbContainer>
      <MobileMargin />
      <div className="copyFullImage">
        <div className="copyChartElement">
          <LeadingIssueCard
            period={period}
            departmentId={departmentId}
            subDepartmentId={subDepartmentId}
            setLoading={setLoading}
            location={location}
          />
        </div>
        {visibility_constant && (
          <>
            <LeadingIssuesQuestions
              period={period}
              departmentId={departmentId}
              subDepartmentId={subDepartmentId}
              setLoading={setLoading}
              location={location}
            />
            <div className="copyDemographicInsights">
              <MoreInsightsCard
                period={period}
                departmentId={departmentId}
                subDepartmentId={subDepartmentId}
                setLoading={setLoading}
                location={location}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const HoverWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
`;

const MobileMargin = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 0;

  @media screen and (max-width: 715px) {
    margin-bottom: 30px;
  }
`;

export default memo(LeadingIssuesPage);

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toggleSideBar } from '../Actions/app.actions';

import InviteToJoin from './inviteToJoin';

import { ReactComponent as MinimiseLogo } from '../Assets/Icons/Sidebar/minimise.svg';
import { ReactComponent as ExpandLogo } from '../Assets/Icons/Sidebar/expand.svg';
import { CompanyDashboardLinks } from '../Data/Menus';
import CustomTooltip from './customTooltip';
import { useTranslation } from 'react-i18next';

const Sidebar = ({ isMobile = false }) => {
  const [subMenuState, setSubMenuState] = useState({});
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSideBarOpened = useSelector((store) => store.app.isSideBarOpened);
  const userDetails = useSelector((store) => store.company?.userSettings?.userDetails);
  const profileData = useSelector((store) => store.company?.profile);

  const toggleSubmenu = (title) => {
    if (!isSideBarOpened) dispatch(toggleSideBar());
    setSubMenuState((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  const checkSubRouteActive = (link) => {
    return !!link.children.find(({ url }) => url === location.pathname);
  };

  useEffect(() => {
    setSubMenuState((prevState) => {
      return CompanyDashboardLinks(
        profileData?.only_heartbeat,
        null,
        profileData?.is_analytics,
        t,
        profileData.id,
      ).reduce((subMenuState, { title, children }) => {
        if (children) {
          subMenuState[title] = !!children.find(({ url }) => url === location.pathname) || prevState[title];
        }
        return subMenuState;
      }, {});
    });
  }, [location.pathname, profileData?.only_heartbeat]);

  const toggleSidebarAction = () => dispatch(toggleSideBar());

  return (
    <DashSidebar open={isSideBarOpened}>
      {!isMobile &&
        (isSideBarOpened ? (
          <ToggleLogo as={MinimiseLogo} onClick={toggleSidebarAction} />
        ) : (
          <ToggleLogo as={ExpandLogo} onClick={toggleSidebarAction} />
        ))}
      <DashNav isMobile={isMobile} isSideBarOpened={isSideBarOpened}>
        {CompanyDashboardLinks(
          profileData?.only_heartbeat,
          profileData?.survey_limit,
          profileData?.is_analytics,
          t,
          profileData.id,
        ).map((item) => {
          return (
            <DashItem
              key={item.id}
              // style={{
              //   cursor: item.isPublic ? 'inherit' : 'not-allowed',
              // }}
            >
              {isSideBarOpened && item?.children ? (
                <>
                  <DashToggle
                    className={`${subMenuState[item.title] && 'opened'} ${checkSubRouteActive(item) && 'active'}`}
                    onClick={() => toggleSubmenu(item.title)}
                  >
                    <Logo as={item.logo} />
                    {item.title}
                  </DashToggle>
                  {subMenuState[item.title] && (
                    <DashSubNav
                    // style={{
                    //   cursor: item.isPublic ? 'inherit' : 'not-allowed',
                    // }}
                    >
                      {item.children.map(({ url, title, isPublic, tooltipText }) => {
                        if (
                          item.title === 'Settings' &&
                          url === '/settings/user' &&
                          !userDetails?.company_master_account
                        )
                          return null;

                        return tooltipText ? (
                          <CustomTooltip title={tooltipText}>
                            <FakeDashLink key={url} to="#">
                              {title}
                            </FakeDashLink>
                          </CustomTooltip>
                        ) : (
                          <DashLink
                            key={url}
                            to={url}

                            // disabled={isPublic ? false : true}
                          >
                            {title}
                          </DashLink>
                        );
                      })}
                    </DashSubNav>
                  )}
                </>
              ) : item.url ? (
                <DashLink
                  exact={item.exact}
                  to={item.url}
                  // disabled={item.isPublic ? false : true}
                >
                  <Logo as={item.logo} />
                  {isSideBarOpened && item.title}
                </DashLink>
              ) : (
                <DashLinkWithoutLink onClick={() => toggleSubmenu(item.title)}>
                  <Logo as={item.logo} />
                  {isSideBarOpened && item.title}
                </DashLinkWithoutLink>
              )}
            </DashItem>
          );
        })}
      </DashNav>
      {isSideBarOpened && <InviteToJoin isSidebar text={t('inviteUsersToJoinPlatform')} />}
    </DashSidebar>
  );
};

const Logo = styled.svg`
  width: 20px;
  height: 20px;
`;

const ToggleLogo = styled.svg`
  position: absolute;
  right: 32px;
  top: 30px;
  cursor: pointer;

  &:hover {
    path {
      fill: #00c8a0;
    }
  }
`;

const StyledLink = styled(NavLink)`
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryLight};
  font-weight: ${({ theme }) => theme.fontMedium};
  text-decoration: none;
`;
StyledLink.displayName = 'StyledLink';

const DashSidebar = styled.div`
  position: relative;
  width: 264px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #1a5549;

  ${({ open }) =>
    !open &&
    css`
      width: 50px;
      //margin-bottom: 16px;

      ${ToggleLogo} {
        right: 50%;
        transform: translateX(50%);
      }
      ${DashLink}, ${DashLinkWithoutLink} {
        padding: 0;
        width: 100%;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
      ${Logo} {
        margin: 0 !important;
      }
    `}
`;

const DashNav = styled.ul`
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? '75px' : '115px')};
  overflow: auto;
  height: ${({ isSideBarOpened }) => (isSideBarOpened ? 'calc(100vh - 248px)' : '100%')};
`;

const DashItem = styled.li`
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
  }
`;

const DashLink = styled(StyledLink)`
  padding: 14px ${({ theme }) => theme.spacingLg};
  display: flex;
  align-items: center;
  opacity: 0.5;
  color: white !important;
  font-weight: 600;

  svg {
    margin-right: 20px;

    path {
      color: white;
    }
  }

  &:hover {
    color: white;
    opacity: 0.8;
  }

  &.active {
    background-color: #00c8a0;
    color: ${({ theme }) => theme.white};
    opacity: 1;

    svg {
      margin-right: 20px;

      path {
        color: white;
      }
    }
  }
`;

const FakeDashLink = styled.span`
  padding: 14px ${({ theme }) => theme.spacingLg};
  display: flex;
  align-items: center;
  opacity: 0.5;
  color: white !important;
  font-weight: 600;
  padding-left: 72px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  font-weight: normal;
  font-size: 16px;

  svg {
    margin-right: 20px;

    path {
      color: white;
    }
  }

  &:hover {
    color: white;
    opacity: 0.8;
  }

  &.active {
    background-color: #00c8a0;
    color: ${({ theme }) => theme.white};
    opacity: 1;

    svg {
      margin-right: 20px;

      path {
        color: white;
      }
    }
  }
`;
const DashLinkWithoutLink = styled.a`
  display: flex;
  align-items: center;
  opacity: 0.5;
  color: white !important;
  font-weight: 500;

  svg {
    margin-right: 20px;

    path {
      color: white;
    }
  }

  &:hover {
    color: white;
    opacity: 0.8;
  }

  &.active {
    background-color: #00c8a0;
    color: ${({ theme }) => theme.white};
    opacity: 1;

    svg {
      margin-right: 20px;

      path {
        color: white;
      }
    }
  }
`;

const DashToggle = styled.div`
  padding: 14px ${({ theme }) => theme.spacingLg};
  font-size: 16px;
  color: ${({ theme }) => theme.white};
  font-weight: 600;
  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
  opacity: 0.5;

  svg {
    margin-right: 20px;

    path {
      color: white;
    }
  }

  &:hover {
    color: white;
    opacity: 0.8;
  }

  &.opened {
    opacity: 1;
    &::after {
      right: 32px;
      //transform: rotate(-135deg) translate(45%);
    }
  }

  &.active {
    background-color: #00c8a0;
    color: ${({ theme }) => theme.white};
    opacity: 1;

    &::after {
      border-color: ${({ theme }) => theme.white};
    }
  }

  &::after {
    width: 12px;
    height: 12px;
    right: 32px;
    top: 50%;
    display: block;
    content: '';
    position: absolute;
    border-style: solid;
    border-color: ${({ theme }) => theme.white};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-100%);
  }
`;
DashToggle.displayName = 'DashToggle';

const DashSubNav = styled.ul`
  padding: 0;
  ${DashLink} {
    padding-left: 72px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    font-weight: normal;

    &:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.25);
    }

    &:last-child {
      border: none;
    }
    
    &.active {
      background-color:initial;
      color: #00c8a0 !important;
    }
`;
DashSubNav.displayName = 'DashSubNav';

const DashSubItem = styled.li`
  a {
    color: white !important;
  }
`;
DashSubItem.displayName = 'DashSubItem';

const DashSubLink = styled(StyledLink)`
  font-weight: 400;
  &.active {
    color: ${({ theme }) => theme.primary} !important;
  }
`;
DashSubLink.displayName = 'DashSubLink';

export default Sidebar;

import {
  LOGIN,
  LOGOUT,
  MAP_STORAGE_TO_REDUX,
  GET_PROFILE,
  GET_ALL_DASHBOARD,
  GET_DEPARTMENTS,
  GET_LOCATIONS,
  FILTER_LEADING_ISSUE,
  FILTER_ACTIVITY_LEVEL,
  FILTER_EMPLOYEE_COUNT,
  GET_CONTRACT,
  GET_USER_LIST,
  GET_USER_DETAILS,
  GET_INVITATION_DETAILS,
  GET_MANAGER_LIST,
  GET_EMPLOYEE_LIST,
  GET_INVITED_EMPLOYEE_LIST,
} from '../Constants/actionTypes';
import { RESTRICTED_COMPANEY_IDS } from '../Util/constants';

const initialState = {
  role: null,
  profile: null,
  dashboard: null,
  departments: null,
  locations: null,
  contract: null,
  userSettings: null,
  managerList: null,
  employeeList: null,
  employeeInvitedList: null,
  invitationDetails: null,
  visibility_constant: false
};

export default function company(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        role: action.payload?.role,
      };
    case GET_PROFILE:
      const VISIBILITY_CONSTANT = !RESTRICTED_COMPANEY_IDS?.includes(action.payload?.id);
      return {
        ...state,
        profile: action.payload,
        visibility_constant: VISIBILITY_CONSTANT
      };
    case MAP_STORAGE_TO_REDUX:
      return {
        ...state,
        role: action.payload?.role,
      };
    case GET_ALL_DASHBOARD:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ...action.payload,
        },
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };

    case GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };

    case GET_MANAGER_LIST:
      return {
        ...state,
        managerList: action.payload,
      };
    case GET_EMPLOYEE_LIST:
      return {
        ...state,
        employeeList: action.payload,
      };
    case GET_INVITED_EMPLOYEE_LIST:
      return {
        ...state,
        employeeInvitedList: action.payload,
      };
    case FILTER_LEADING_ISSUE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          employees_mood_box_stats: action.payload,
        },
      };
    case FILTER_ACTIVITY_LEVEL:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          activity_over_time: action.payload,
        },
      };
    case FILTER_EMPLOYEE_COUNT:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          employess_stats: action.payload,
        },
      };
    case GET_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      };
    case GET_USER_LIST:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          userList: action.payload,
        },
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          userDetails: action.payload,
        },
      };
    case GET_INVITATION_DETAILS:
      return {
        ...state,
        invitationDetails: action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

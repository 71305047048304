import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { Table, Progress } from 'antd';

import Button from '../../Components/button';
import { useTranslation } from 'react-i18next';
import { getContract } from '../../Actions/company.actions';
import HeaderName from '../../Components/headerName';
import { RESTRICTED_COMPANEY_IDS } from '../../Util/constants';

const Contract = () => {
  const contract = useSelector((store) => store.company.contract);
  const profileData = useSelector((store) => store.company?.profile);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getContract());
  }, [dispatch]);

  const dataWithoutVideos = useMemo(
    () => [
      {
        name: t('noOfEmployees'),
        value: contract?.total_employees,
        percentage: null,
        info: t('textExampleForTooltip'),
      },
      {
        name: t('contractRenewsOn'),
        value: contract?.contract_renews_on,
        percentage: null,
      },
    ],
    [contract],
  );

  const data = useMemo(
    () => [
      {
        name: t('noOfEmployees'),
        value: contract?.total_employees,
        percentage: null,
        info: t('textExampleForTooltip'),
      },
      {
        name: t('totalVideos'),
        value: contract?.total_videos,
        percentage: null,
        info: t('textExampleForTooltip'),
      },
      {
        name: t('videosConsumed'),
        value: contract?.total_consumed_videos,
        percentage: 60,
        info: t('textExampleForTooltip'),
      },
      {
        name: t('videosRemaining'),
        value: contract?.total_remaining_videos,
        percentage: 20,
        info: t('textExampleForTooltip'),
      },
      {
        name: t('contractRenewsOn'),
        value: contract?.contract_renews_on,
        percentage: null,
      },
    ],
    [contract],
  );

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        render: (text) => <div style={{ fontWeight: 700 }}>{text}</div>,
      },
      {
        title: t('value'),
        dataIndex: 'value',
        key: 'value',
      },
      {
        title: t('percentage'),
        dataIndex: 'percentage',
        key: 'percentage',
        width: 100,
        render: (percentage) => (
          <Progress strokeColor={percentage > 50 ? '#EE7E7E' : '#CFF67C'} percent={+percentage} showInfo={false} />
        ),
      },
    ],
    [],
  );

  return (
    <div style={{ maxWidth: 688 }}>
      <HeaderName>{t('contract')}</HeaderName>
      <StyledTable
        columns={columns}
        dataSource={RESTRICTED_COMPANEY_IDS?.includes(profileData?.id) ? dataWithoutVideos : data}
        showHeader={false}
        pagination={false}
        rowKey="name"
        loading={contract === null}
      />
      <div style={{ marginTop: 60 }}>
        <p>{t('enquiry')}</p>
        <Button type="primary">{t('800SUPPORT')}</Button>
      </div>
    </div>
  );
};

const StyledTable = styled(Table)`
  tr {
    td {
      padding: 12px 16px;
    }
  }
`;

export default Contract;
